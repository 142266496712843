import React from 'react';
import Hero from '../components/sections/Hero';


// import FeaturesTiles from '../components/sections/FeaturesTiles';
// import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Testimonial from '../components/sections/Testimonial';
// import Pricing from '../components/sections/Pricing';
// import Cta from '../components/sections/Cta';
import { Helmet } from 'react-helmet';

class Home extends React.Component {

  state = {
    videoModalActive: false
  }
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  

  render() {

    const seo = {
      title: '3D artificial intelligence for gardens',
      description: '',
      url: 'https://www.3co.ai',
      image: 'https://www.3co.ai/logo192.png',
      canonical: 'https://www.3co.ai',
      keywords: 'Augmented Reality, 3D Scanning, 3D Modeling, Horticulture, Landscape Architecture, Garden Design, Ecology'
    };

    return (

        <React.Fragment>
	  <Helmet>
            <meta charSet="utf-8" />
            <title>3co | { seo.title }</title>
            <link rel="canonical" href={ seo.canonical }/>
            <meta name="description" content={ seo.description } />
            <meta name="keywords" content={ seo.keywords } />
          </Helmet>
          <Hero className="illustration-section-01" />
        {/* <FeaturesTiles />        */}
        {/* <Testimonial topDivider /> */}  

        </React.Fragment>
    );
  }
}

export default Home;

//         <FeaturesSplit invertMobile imageFill className="illustration-section-02" /> 
//        <Pricing topDivider />
//         <Cta split />
