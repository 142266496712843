import React from 'react';
import './App.css'
import { Routes, Route } from 'react-router-dom';
//import { useNavigate } from 'react-router';


// import AppRoute from './utils/AppRoute';
//import ScrollReveal from './utils/ScrollReveal';
//import ReactGA from 'react-ga';
// import '@google/model-viewer';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
// import About from './views/About';
// // import Contact from './views/Contact';
// // import Specs from './views/Specs';
// import Hobbyist from './views/Hobbyist';
// import Enterprise from './views/Enterprise';
// import Privacy from './views/Privacy';
// import Terms from './views/Terms';
// import Demo from './views/Demo';
// import Demo2 from './views/Demo2';
// import Banana from './views/Banana';
// import Art from './views/Art';
// import GooglePresentation from './views/GooglePresentation';
// import GooglePresentationKiwi from './views/GooglePresentationKiwi';
// import MuralArt from './views/MuralArt';
// import SexerArtificial from './views/SexerArtificial';


// Initialize Google Analytics
//ReactGA.initialize('UA-116651738-1');

// const trackPage = page => {
//  ReactGA.set({ page });
//  ReactGA.pageview(page);
//};


class App extends React.Component {

  render() {
    return (
      <div className="nunito">
         <LayoutDefault>
	   <Routes>
	    <Route exact path="/" element={<Home/>} />
           </Routes>
	 </LayoutDefault>
      </div>
    );
  }
}

export default App;


// withRouter(props => <App {...props} />);




// 


//  componentDidMount() {
//    const location = useLocation();
//    const page = location.pathname;
//    document.body.classList.add('is-loaded')
//    this.refs.scrollReveal.init();
//    trackPage(page); 
//  }

//  componentDidUpdate(prevProps) {
//    const currentPage = prevProps.location.pathname + prevProps.location.search;
//    const nextPage = this.props.location.pathname + this.props.location.search;    
//    if (currentPage !== nextPage) {
//      this.refs.scrollReveal.init();
//      trackPage(nextPage);
//    }
//  }






              // <AppRoute path="/ar" component={Art} layout={LayoutDefault} />
              // <AppRoute path="/specs" component={Specs} layout={LayoutDefault} />
              // <AppRoute path="/hobbyist" component={Hobbyist} layout={LayoutDefault} />
              // <AppRoute path="/enterprise" component={Enterprise} layout={LayoutDefault} />
              // <AppRoute path="/privacy" component={Privacy} layout={LayoutDefault} />
              // <AppRoute path="/terms" component={Terms} layout={LayoutDefault} />
              // <AppRoute path="/about" component={About} layout={LayoutDefault} />
              // <AppRoute path="/contact" component={Contact} layout={LayoutDefault} />
              // <AppRoute path="/web-1" component={Demo} layout={LayoutDefault} />
              // <AppRoute path="/3d_web" component={Demo2} layout={LayoutDefault} />
              // <AppRoute path="/banana" component={Banana} layout={LayoutDefault} />
              // <AppRoute path="/walls" component={MuralArt} layout={LayoutDefault} />
              // <AppRoute path="/pitch" component={GooglePresentation} layout={LayoutDefault} />
              // <AppRoute path="/kiwi" component={GooglePresentationKiwi} layout={LayoutDefault} />
              // <AppRoute path="/art" component={SexerArtificial} layout={LayoutDefault} />
