import React from 'react';
// import '../App.css'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';

const LayoutDefault = ({ children }) => {
 return (
  <div>
    <Header style={{position: "fixed", top: 0, zIndex: 1}} />
    <main className="site-content">
       { children }
    </main>
    <Footer />
  </div>
	 
);
};
export default LayoutDefault;  

// React.Fragment>
