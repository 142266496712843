import React, { useRef, useEffect } from "react";
import '../../App.css'
// import DynamicFont from 'react-dynamic-font';
// import ReactPlayer from "react-player"; , { useEffect, useRef }
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";

import headVideo from "./../../assets/videos/3D_scan.mp4"
// import secondVideo from "./../../assets/videos/3D_render.mp4" 

import letItGrow from "./../../assets/videos/3co.mp4"

// import augmentedRealityDemoVideo from "./../../assets/videos/web_to_ar.mp4"

import renderWithColorsImage from "./../../assets/images/render_colors_v2.jpg"
import renderWithoutColorsImage from "./../../assets/images/render_no_colors_v2.jpg"

// import headlineValueProp from "./../../assets/images/3co_3d_commerce.png"
import poster1 from "./../../assets/images/3D_scan.jpg"
// import poster2 from "./../../assets/images/3D_render.jpg"
import poster3 from "./../../assets/images/3co_let_it_grow.jpg"

import lance from "./../../assets/images/lance_legel.jpg"
import rob from "./../../assets/images/rob_cohn.jpg"

import nursery from "./../../assets/images/hibiscus_beauty.jpg"
import nurseryColors from "./../../assets/images/nursery_colors.jpg"

import commerce from "./../../assets/images/commerce.jpg"


import techstars from "./../../assets/images/techstars.png"
import google from "./../../assets/images/google.png"

import dragon_usdz from "./../../assets/models/dragon.usdz"
import dragon_glb from "./../../assets/models/dragon.glb"


import augmented_reality_button from "./../../assets/images/augmented_reality_button.png"

// import moon from "./../../assets/images/moon_1k.hdr"
// import neilPhoto from "./../../assets/images/NeilArmstrong.webp"
// import neil3DModel from "./../../assets/images/NeilArmstrong.glb"

import classes from './BackgroundVideo.module.css';
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';
// import { Text, StyleSheet } from "react-native";


// import ButtonGroup from '../elements/ButtonGroup';
// import Button from '../elements/Button';
// import Image from "../elements/Image";
// import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1
        }}
    />
);

const isMobile = window.innerWidth <= 768;



function ModelViewerComponent({ glbSrc, usdzSrc}) {
    const modelViewerRef = useRef(null);

    // const arButtonStyle = {
    //     width: '200px',
    //     height: isMobile ? '52px' : '1px',
    //     display: isMobile ? 'block' : 'none',
    //     backgroundImage: `url(${augmented_reality_button})`,
    //     backgroundSize: '1059px 277px',
    //     backgroundColor: 'transparent',
    //     border: 'none',
    //     color: 'white',  // Adjust the text color if needed,
    //     position: 'absolute',  // Position the button absolutely
    //     bottom: '-60px',        // Place it 10px from the bottom
    //     left: '50%',           // Center it horizontally
    //     transform: 'translateX(-50%)',  // Adjust for the button's width to ensure true centering
    //     marginBottom: '0px'
    // };

    const arButtonStyle = {
        width: '200px',
        height: isMobile ? '52px' : '1px',
        display: isMobile ? 'block' : 'none',
        backgroundImage: `url(${augmented_reality_button})`,
        backgroundSize: '200px 52px',  // This ensures the image scales to fit the button's dimensions
        backgroundColor: 'transparent',
        border: 'none',
        color: 'white',
        position: 'absolute',
        bottom: '-60px',
        left: '50%',
        transform: 'translateX(-50%)',
        marginBottom: '0px'
    };


    const styles = {
        width: '100%',
        height: isMobile ? '30vh' : '700px',
        // boxShadow: '0 0 0px 1px #404040',
        position: 'relative',
    };

    // Adjust the cameraTarget property upon model load
    const handleModelLoad = () => {
        console.log("Model has been loaded!"); // Print message to console

        const modelViewerElement = modelViewerRef.current;
        if (modelViewerElement) {
            
            // const xOffset = 0.00;  // Modify as required
            // const yOffset = 0.00;  // Modify as required
            // const zOffset = 0.00; // Modify as required

            const currentCameraTarget = modelViewerElement.getCameraTarget();
            console.log("Camera target " + currentCameraTarget)

            // const x = parseFloat(currentCameraTarget.x) + xOffset;
            // const y = parseFloat(currentCameraTarget.y) + yOffset;
            // const z = parseFloat(currentCameraTarget.z) + zOffset;

            // modelViewerElement.cameraTarget = `${x}m ${y}m ${z}m`;

                }
    };

    useEffect(() => {
        const modelViewerElement = modelViewerRef.current;
        if (modelViewerElement) {
            modelViewerElement.addEventListener('load', handleModelLoad);
            return () => {
                modelViewerElement.removeEventListener('load', handleModelLoad);
            };
        }
    }, []);


    useEffect(() => {
        const arButton = document.getElementById('ar-button-outside');
        
        const activateAR = () => {
            const modelViewerElement = document.querySelector('model-viewer');
            if (modelViewerElement) {
                modelViewerElement.activateAR();
            }
        };
        
        arButton.addEventListener('click', activateAR);
        
        // Clean up the event listener when the component is unmounted
        return () => {
            arButton.removeEventListener('click', activateAR);
        };
    }, []);

    return (
      <div className="model-viewer-container">

        <style>
            {`
                model-viewer::part(ar-button) {
                    display: none;
                }
            `}
        </style>

        <model-viewer 
            ref={modelViewerRef}
            src={glbSrc} 
            ios-src={usdzSrc}
            onLoad={handleModelLoad}
            alt="3D model produced by 3co A.I." 
            auto-rotate 
            ar 
            ar-modes="scene-viewer quick-look"
            ar-scale="auto"
            camera-controls 
            touch-action="pan-y" 
            disable-zoom
            style={styles} 
            camera-orbit="10deg 90deg 0.60m" 
            shadow-intensity="1"
            >
            
        </model-viewer>


        <button style={arButtonStyle} id="ar-button-outside"></button>
      

      </div>
    );
}


class HeroFull extends React.Component {  
  state = {
    videoModalActive: false,
  };
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "hero section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    return (
      <section {...props} className={outerClasses}>
      
      <div className="container nunito-light">
            
            <ColoredLine color="#404040" />
            <p style={{ fontSize: isMobile ? 28 : 40, lineHeight: 1.1, overflow: 'hidden', width: '100%'}}>
              3D artificial intelligence{isMobile && <br />} for home & garden retail
	    
            </p>

            <ColoredLine color="#404040" />
      </div>


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
        <div style={{ width: isMobile ? '100%' : '60%', paddingLeft: isMobile ? 16 : 24, paddingRight: isMobile ? 16 : 24, margin: 0 }}>
          <ModelViewerComponent glbSrc={dragon_glb} usdzSrc={dragon_usdz} />
        </div>
      </div>
      
      <br></br>
      <br></br>
      <div className="container nunito-light">
            
            <br></br>
            <ColoredLine color="#404040" />
            
            <p style={{  fontSize: isMobile ? 14 : 26, lineHeight: 1.1, overflow: 'hidden', width: '100%'}}>
             Create accurate 3D models of real world products using just an iPhone equipped with 3co A.I.
            </p>

            <ColoredLine color="#404040" />
      </div>

      <br></br>

      <div className="container nunito-light">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
          <div style={{ width: isMobile ? '100%' : '100%' , padding: 0, marginLeft: isMobile ? 0 : 0, marginRight: isMobile ? 0 : 0 }}>
            <video playsInline autoPlay loop muted className={classes.Video} poster={poster1} preload="metadata">
                <source src={headVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <br></br> 

      <div className="container nunito-light">
            <ColoredLine color="#404040" />
            <p style={{  fontSize: isMobile ? 14 : 26, lineHeight: 1.1, overflow: 'hidden', width: '100%'}}>
             3D models unlock augmented reality, which can increase retail sales by 50% (<i><a href="https://www.shopify.com/blog/ar-shopping" target="_blank" rel="noopener noreferrer">Shopify</a></i>). 
            
            </p>
            <ColoredLine color="#404040" />
      </div>

      <br></br>

      <div className="container nunito-light"> 
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
          
          <div style={{ width: '100.0%', padding: 0, margin: 0 }}>
            <img src={commerce} alt="3co prototype augmented reality app developed in 2018" style={{ width: '100%' }} />
          </div>
          
        </div>
      </div>

      <br></br>
      <div className="container nunito-light">
            <ColoredLine color="#404040" />
            <p style={{  fontSize: isMobile ? 14 : 26, lineHeight: 1.1, overflow: 'hidden', width: '100%'}}>
             3co is now 3D modeling plants from <i><a href="https://www.fngla.org/resources/economic-impact-florida-nursery-and-landscape-industry" target="_blank" rel="noopener noreferrer">Florida nurseries</a></i>,{!isMobile && <br />} who supply most U.S. sales of tropical and indoor plants.
              
             </p> 
            <ColoredLine color="#404040" /> 
      </div>

      <br></br>

      <div className="container nunito-light">    
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
          <div style={{ width: '50.0%', padding: 0, marginRight: isMobile ? 2 : 10 }}>
            <img src={nursery} alt="Hibiscus rosa-sinensis captured in Fairchild Botanical Gardens, Miami, FL" style={{ width: '100%' }} />
          </div>
          <div style={{ width: '50.0%', padding: 0, marginLeft: isMobile ? 2 : 10 }}>
            <img src={nurseryColors} alt="Plants from a nursery based in Homestead, Florida of a 3co partner" style={{ width: '100%' }} />
          </div>

        </div>
      </div>

      <br></br>

      <div className="container nunito-light">
            <ColoredLine color="#404040" />
            <p style={{ fontSize: isMobile ? 14 : 26, lineHeight: 1.1, overflow: 'hidden', width: '100%'}}>
        
              3co was started with funding from <i><a href="https://jaarverslag.royalfloraholland.com/en/" target="_blank" rel="noopener noreferrer"> Royal FloraHolland</a></i>,{!isMobile && <br />} which sells 20,000 cultivars for €5 billion / year.
              
            </p>
            <ColoredLine color="#404040" />
      </div>

      <br></br>

      <div className="container nunito-light"> 
        <div style={{ width: '100.0%', padding: 0, margin: 10 }}>
          
            <video playsInline autoPlay loop controls className={classes.Video} poster={poster3} preload="metadata" muted>
              <source src={letItGrow} type="video/mp4" />
                  Your browser does not support the video tag.
            </video>
        </div>
      </div>

      <br></br>
    
      <div className="container nunito-light">
            
            <ColoredLine color="#404040" />
            
            <p style={{  fontSize: isMobile ? 14 : 26, lineHeight: 1.1, overflow: 'hidden', width: '100%'}}>
             3co was then funded by <a href="https://jobs.techstars.com/companies/3co" target="_blank" rel="noopener noreferrer">Techstars</a> in London, and featured by <a href="https://cloud.google.com/blog/topics/startups/3co-scales-ar-commerce-with-3d-scanning" target="_blank" rel="noopener noreferrer">Google</a> in a tech deep-dive.
            </p>

            <ColoredLine color="#404040" />
      </div>

        <br></br>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
        <div style={{ width: isMobile ? '50%' : '30%', padding: 0, margin: 0 }}>
          <a href="https://jobs.techstars.com/companies/3co" target="_blank" rel="noopener noreferrer">  
            <img src={techstars} alt="3co is a Techstars company" style={{ width: isMobile ? '100%' : '50%'}} />
          </a>
        </div>
        <div style={{ width: isMobile ? '50%' : '30%', padding: 0, margin: 0 }}>
          <a href="https://cloud.google.com/blog/topics/startups/3co-scales-ar-commerce-with-3d-scanning" target="_blank" rel="noopener noreferrer"> 
            <img src={google} alt="3co was featured in a Google publication" style={{ width: isMobile ? '100%' : '50%' }} />
          </a>
        </div>
      </div>

        <br></br>

      <div className="container nunito-light">
            
            <ColoredLine color="#404040" />
            <p style={{ fontSize: isMobile ? 14 : 28, lineHeight: 1.1, overflow: 'hidden', width: '100%'}}>
              In 2024, 3co will launch an A.I. co-pilot for plant retailers, with augmented reality software for real-time on-site 3D rendering of plants that are most likely to thrive.
            </p>
            <ColoredLine color="#404040" />
            
      </div>

      <br></br>

      <div className="container nunito-light">

        <ReactCompareSlider
          itemOne={<ReactCompareSliderImage src={renderWithColorsImage} />} // srcSet="..."  alt="Render with colors"
          itemTwo={<ReactCompareSliderImage src={renderWithoutColorsImage}  />} // srcSet="..."  alt="Render without colors"
          handle={
                <ReactCompareSliderHandle
                  buttonStyle={{
                    backdropFilter: undefined,
                    background: "white",
                    border: 100,
                    color: "#151719",
                    width: 43,
                    height: 43,
                  }}
          />}
          position = {50}
        />
      </div>

          <br></br>
          <br></br>

      <div className="container nunito-light">
        <div className="nunito-light" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
            <div style={{ width: '50%', padding: 0, margin: 0, textAlign: 'center', paddingLeft: isMobile ? 2 : 10, paddingRight: isMobile ? 2 : 10}}>       
                <div style={{ width: '100%', fontSize: isMobile ? 13 : 22, textAlign: 'center', lineHeight: 1.0,}}>
                    <strong><a href="https://www.linkedin.com/in/legel/" target="_blank" rel="noopener noreferrer"> Lance Legel</a> is CEO of 3co.</strong><br/> 
                    Once a NASA astrophysicist, Lance has built AI for Fortune 500 companies. 
                    He earned a MS in AI (deep learning on supercomputers).
                </div>
            </div>
            <div style={{ width: '50%', padding: 0, margin: 0, textAlign: 'center', paddingLeft: isMobile ? 2 : 10, paddingRight: isMobile ? 2 : 10}}>
                <div style={{ width: '100%', fontSize: isMobile ? 13 : 22, textAlign: 'center', lineHeight: 1.0}}>
                    <strong><a href="https://rwcohn.github.io/homepage/" target="_blank" rel="noopener noreferrer">Robert Cohn</a> is CTO of 3co.<br/></strong> 
                    Rob was a software engineer for Google & Microsoft.<br/> 
                    He earned a PhD in AI (probabilistic modeling with interactive agents). 
                </div>
            </div>
        </div>

        <br></br>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
          <div style={{ width: '50%', padding: 0, marginRight: isMobile ? 2 : 10  }}>
            <img src={lance} alt="Lance Legel, CEO of 3co" style={{ width: '100%' }} />
          </div>
          <div style={{ width: '50%', padding: 0, marginLeft: isMobile ? 2 : 10  }}>
            <img src={rob} alt="Robert Cohn, CTO of 3co" style={{ width: '100%' }} />
          </div>
        </div>
      </div>


      <div className="container nunito-light">
            
            <br></br>
            <ColoredLine color="#404040" />
            <p style={{ fontSize: isMobile ? 28 : 30, lineHeight: 1.5, overflow: 'hidden', width: '100%'}}>
              Let's change the world 🌎 
            </p>
            <p style={{ fontSize: isMobile ? 18 : 22, lineHeight: 1.2, overflow: 'hidden', width: '100%'}}>
              🌴 hi@3co.ai
            </p>


            <ColoredLine color="#404040" />
      </div>


      </section>
    );
  }
}


      // <div className="container">
      //       <p style={{ fontWeight: 'light'}}>
      //         <br></br>
      //          Hello, World:
      //         <br></br>
      //         <span style={{ fontWeight: 'bold'}}>hi@3co.ai</span>
      //       </p>
      // </div>

            // <div style={{ fontWeight: 'bold', fontSize: 96, lineHeight: 0.9, overflow: 'hidden', width: '100%'}}>
            //    <DynamicFont content={'MAKE IT REAL'} />
            // </div>

      // <br></br>
      // <div style={{ fontWeight: 'bold', fontSize: 50, lineHeight: 0.9, overflow: 'hidden', width: '100%'}}>
      //    <DynamicFont content={'3D E-COMMERCE'} />
      // </div>

      // <br></br>
      // <div style={{ fontStyle: 'italic', fontSize: 22, lineHeight: 1.1, overflow: 'hidden', width: '100%'}}>
      //    <DynamicFont content={'for'} />
      // </div>
      // <br></br>


  //  <DynamicFont content={'SUPERPOWERED 3D VISION'} />

            //   <div style={{ fontWeight: 'bold', fontSize: 85, lineHeight: 1.0, overflow: 'hidden', width: '100%'}}>
            //    <DynamicFont content={'IMPORT REALITY'} />
            // </div>


            // <span style={{ fontWeight: 'bold'}}> Show, don't tell </span> 
             //  what worlds could become, through presentations so realistic .



            // <Text style={{ fontWeight: 'bold', fontSize: 32, lineHeight: 1.0, overflow: 'hidden', width: '100%'}} adjustsFontSizeToFit numberOfLines={1}>3co drives forward the massive artistic visions of the world\'s leading retailers, designers, and creators.</Text>

  //            // <div style={{ fontWeight: 'bold', fontSize: 120, lineHeight: 1}}>
            //   SUPERPOWERED 3D VISION
            // </div>
                // <ReactPlayer  url="https://www.youtube.com/embed/AHhxihaoOwc" 
                //               playing={true}
                //               controls={false}
                //               width={1200}
                //               height={675}/> 
            // <div
            //   className="hero-figure reveal-from-bottom illustration-element-01"
            //   data-reveal-value="20px"
            //   data-reveal-delay="800"
            // >
      // <div className="background-video">
      //       <ReactPlayer  url={myVideo}   
      //         playing={true}
      //         controls={false}
      //         width={100p}/> 
      // </div>

              //   <video
              //   src={require("./../../assets/videos/iris_3d_machine_vision.mp4")}
              //   autoPlay
              //   mute
              //   controls
              //   loop
              //   style={{ width: "100%", height: "100%" }}
              // ></video>  // ./../../assets/videos/iris_3d_machine_vision.mp4


            // </div>
            // {/* <Modal
            //   id="video-modal"
            //   show={this.state.videoModalActive}
            //   handleClose={this.closeModal}
            //   video="https://player.vimeo.com/video/408538416"
            //   videoTag="iframe"
            // /> */}


            // <div style={{ fontWeight: 'bold', fontSize: 180, lineHeight: 0.9, overflow: 'hidden', width: '100%'}}>
            //    <DynamicFont content={'SEE'} />
            // </div>


      // <div className="container">
      //   <div style={{ width: '100%'}}>
      //     <model-viewer alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum" 
      //                   src={neil3DModel} 
      //                   ar 
      //                   environment-image={moon} 
      //                   poster={neilPhoto} 
      //                   shadow-intensity="1" 
      //                   camera-controls 
      //                   touch-action="pan-y" 
      //                   generate-schema>
      //     </model-viewer>
      //   </div>
      // </div>


        
        // <div style={{ width: '42.85%', padding: 0, margin: 0 }}> 
          
        //   <video playsInline autoPlay loop muted controls className={classes.Video} poster={poster2} preload="metadata">
        //       <source src={secondVideo} type="video/mp4" />
        //       Your browser does not support the video tag.
        //   </video>

        // </div>

      // <video playsInline autoPlay muted controls className={classes.Video} poster="web_to_ar_still.jpg" preload="metadata">
      //     <source src={augmentedRealityDemoVideo} type="video/mp4" />
      //     Your browser does not support the video tag.
      // </video>

      // <div className="container">
      //   <div style={{ fontWeight: 'bold', fontSize: 120, lineHeight: 0.9, justifyContent: 'center', width: '100%'}}>
      //      <DynamicFont content={'3D VISION'} />
      //   </div>
      // </div>

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
